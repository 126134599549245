import { DocumentType, ModelType } from '@innedit/innedit-type';
import { DocumentData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from '../index';

const ListItemSchemaCollection: FC<
  ListItemProps<DocumentType<any>, DocumentData>
> = ({ docId, index, onClick, model }) => {
  const [doc, setDoc] = useState<ModelType>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const { label } = doc; // model.labelFields.map(field => doc[field]).join(' - ');

  return (
    <ListItem
      displayActionId={false}
      doc={doc}
      index={index}
      label={label ?? ''}
      onClick={onClick}
    />
  );
};

export default ListItemSchemaCollection;
